<template>
  <div>
    <p>Answer each of the questions listed below.</p>

    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Identify each of the compounds below as a metal, a metalloid, or a non-metal.
      </p>

      <v-simple-table style="max-width: 513px">
        <thead>
          <tr>
            <th style="font-size: 12px; width: 25%">
              <stemble-latex content="$\text{Element}$" />
            </th>
            <th style="font-size: 12px; width: 25%">
              <stemble-latex content="$\text{Metal}$" />
            </th>
            <th style="font-size: 12px; width: 25%">
              <stemble-latex content="$\text{Semi-metal}$" />
            </th>
            <th style="font-size: 12px; width: 25%">
              <stemble-latex content="$\text{Non-metal}$" />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="option in options" :key="option.text">
            <td>
              <stemble-latex :content="option.text" />
            </td>
            <td style="text-align: center">
              <v-checkbox v-model="inputs.input1" :value="option.text" />
            </td>
            <td style="text-align: center">
              <v-checkbox v-model="inputs.input2" :value="option.text" />
            </td>
            <td style="text-align: center">
              <v-checkbox v-model="inputs.input3" :value="option.text" />
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question11',
  components: {
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        input1: [],
        input2: [],
        input3: [],
      },
      options: [
        {text: 'Se', value: 'Se'},
        {text: 'Kr', value: 'Kr'},
        {text: 'Si', value: 'Si'},
        {text: 'Sn', value: 'Sn'},
        {text: 'Ga', value: 'Ga'},
        {text: 'As', value: 'As'},
        {text: 'Bi', value: 'Bi'},
      ],
    };
  },
};
</script>
